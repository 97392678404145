import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";
import {request, download} from "../axios"

Vue.use(VueRouter)
// loginType 1 融合平台  2 IEP  3 添加学校
const routes = [
    {
        path: '/login',
        name: 'Login',
        noAuthorize: true,
        component: () => import('../views/Login')
    },
    {
        path: '/loginGate',
        name: 'LoginGate',
        noAuthorize: true,
        component: () => import('../views/Login'),
        meta: {
            keepAlive: false,
            loginType: 1,
        },
    },
    {
        path: '/deviceLogin',
        name: 'DeviceLogin',
        noAuthorize: true,
        component: () => import('../views/Login'),
        meta: {
            keepAlive: false,
            loginType : 2,
        },
    },
    {
        path: '/allEcological',
        name: 'AllEcological',
        noAuthorize: true,
        component: () => import('../views/iep/ecological/AllEcological'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/babyAbilityScale',
        name: 'BabyAbilityScale',
        noAuthorize: true,
        component: () => import('../views/iep/functionalEvaluation/BabyAbilityScale'),
        meta: {
            keepAlive: false
        }
    }, {
        path: '/childrenFeel',
        name: 'ChildrenFeel',
        noAuthorize: true,
        component: () => import('../views/iep/functionalEvaluation/ChildrenFeel'),
        meta: {
            keepAlive: false
        },
    },
    {
        path: '/parentStyleScale',
        name: 'ParentStyleScale',
        noAuthorize: true,
        component: () => import('../views/iep/functionalEvaluation/ParentStyleScale'),
        meta: {
            keepAlive: false
        }
    },
    {
        path: '/manageCenter',
        name: 'ManageCenter',
        noAuthorize: false,
        component: () => import('../views/manageCenter/ManageCenter'),
        meta: {
            keepAlive: false,
            loginType: 1,
        },
    },
    {
        path: '/deviceType',
        name: 'DeviceType',
        meta: {
            loginType: 2,
            keepAlive: false,
        },
        component: () => import('../views/asset/DeviceType')
    }, {
        path: '/growCenter',
        name: 'GrowCenter',
        noAuthorize: false,
        component: () => import('../views/growCenter/GrowCenter'),
        meta: {
            keepAlive: false,
            loginType: 1,
        },
    }, {
        path: '/mobileGrowCenter',
        name: 'MobileGrowCenter',
        noAuthorize: false,
        component: () => import('../views/growCenter/secondLevel/MobileGrowCenter'),
        meta: {
            keepAlive: false,
            loginType: 1,
        },
    }, {
        path: '/assetCenter',
        name: 'AssetCenter',
        noAuthorize: false,
        component: () => import('../views/assetCenter/AssetCenter'),
        meta: {
            keepAlive: false,
            loginType: 1,
        },
    }, {
        path: '/dataCenter',
        name: 'DataCenter',
        noAuthorize: true,
        component: () => import('../views/dataCenter/DataCenter'),
        meta: {
            keepAlive: false,
            loginType: 1,
        },
    }, {
        path: '/assetManage',
        name: 'AssetManage',
        noAuthorize: false,
        component: () => import('../views/assetCenter/AssetManage'),
        meta: {
            keepAlive: false,
            loginType: 1,
        },
    }, {
        path: '/moreAsset',
        name: 'MoreAsset',
        noAuthorize: false,
        component: () => import('../views/assetCenter/assetCenter/MoreAsset'),
        meta: {
            keepAlive: false,
            loginType: 1,
        },
    }, {
        path: '/shareAsset',
        name: 'ShareAsset',
        noAuthorize: false,
        component: () => import('../views/assetCenter/assetCenter/ShareAsset'),
        meta: {
            keepAlive: false,
            loginType: 1,
        },
    },{
        path: '/shareLesson',
        name: 'ShareLesson',
        noAuthorize: false,
        component: () => import('../views/teachResearchCenter/ShareLessonSchedule'),
        meta: {
            keepAlive: false,
            loginType: 1,
        },
    },{
        path: '/assetDetail',
        name: 'AssetDetail',
        noAuthorize: false,
        component: () => import('../views/assetCenter/assetCenter/AssetDetail'),
        meta: {
            keepAlive: false,
            loginType: 1,
        },
    }, {
        path: '/shareAssetDetail',
        name: 'ShareAssetDetail',
        noAuthorize: false,
        component: () => import('../views/assetCenter/assetCenter/ShareAssetDetail'),
        meta: {
            keepAlive: false,
            loginType: 1,
        },
    },{
        path: '/moreList',
        name: 'MoreList',
        noAuthorize: false,
        component: () => import('../views/assetCenter/assetCenter/MoreList'),
        meta: {
            keepAlive: false,
            loginType: 1,
        },
    },
    {
        path: '/assessment',
        name: 'AssessmentCenter',
        noAuthorize: false,
        component: () => import('../views/assessmentCenter/AssessmentCenter'),
        meta: {
            keepAlive: false,
            loginType: 1,
        },
    },
    {
        path: '/teachResearch',
        name: 'TeachResearch',
        noAuthorize: false,
        component: () => import('../views/teachResearchCenter/TeachResearch'),
        meta: {
            keepAlive: false,
            loginType: 1,
        },
    },
    {
        path: '/gate',
        name: 'Gate',
        noAuthorize: false,
        component: () => import('../views/gateWay/Gate'),
        meta: {
            keepAlive: false,
            loginType: 1,
        }
    }, {
        path: '/feedback',
        name: 'Feedback',
        noAuthorize: true,
        component: () => import('../views/manageCenter/itinerantGuid/Feedback'),
        meta: {
            keepAlive: false,
            loginType: 1,
        }
    }, {
        path: '/setSchool',
        name: 'SetSchool',
        noAuthorize: false,
        component: () => import('../views/sys/SetSchool'),
        meta: {
            keepAlive: false,
            loginType: 3,
        }
    }, {
        path: '/loginSetSchool',
        name: 'LoginSetSchool',
        noAuthorize: true,
        component: () => import('../views/Login'),
        meta: {
            keepAlive: false,
            loginType : 3,
        },
    },{
        path: '/filePreview',
        name: 'FilePreview',
        noAuthorize: true,
        component: () => import('../views/FilePreview'),
        meta: {
            keepAlive: false,
            loginType : 3,
        },
    }
]
const adminRoutes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home'),
        children: [
            {
                path: '/index',
                name: 'Index',
                meta: {
                    title: '首页'
                },
                component: () => import('../views/Index')
            },
            {
                path: '/userCenter',
                name: 'UserCenter',
                meta: {
                    title: '个人中心'
                },
                component: () => import('../views/UserCenter')
            },
            // {
            //     path: '/deviceType',
            //     name: 'DeviceType',
            //     meta: {
            //         title: '资产设备'
            //     },
            //     component: () => import('../views/asset/DeviceType')
            // },
        ]
    },

]


const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

//添加路由
router.afterEach((to, from) => {
    //是否已经获取菜单
    let hasRoute = store.state.menus.hasRoutes
    //免受权
    var noAuthorize = false
    routes.forEach(r => {
        if (r.path == to.path && r.noAuthorize != null) {
            noAuthorize = r.noAuthorize
            return
        }
    })
    if (hasRoute || noAuthorize) {
        return
    }
    request.get('/SysMenu/nav').then(res => {
        store.commit("setMenuList", res.data.nav)
        // 拿到用户权限
        store.commit("setPermList", res.data.authoritys)
        // 动态绑定路由
        let newRoutes = adminRoutes
        res.data.nav.forEach(menu => {
            if (menu.children) {
                menu.children.forEach(e => {
                    // 转成路由
                    let route = menuToRoute(e)
                    //查看是否已经存在该路由
                    //路由添加到路由管理中
                    if (route) {
                        newRoutes[0].children.push(route)
                    }
                })
            }
        })
        router.addRoutes(newRoutes)
        store.commit("changeRouteStatus", true)
    })
})

//权限拦截
router.beforeEach((to, from, next) => {
    // var t=location.hash.substring(location.hash.indexOf('?token=') + 7)
    // console.log(t)
    // if (t) {
    //     store.commit("SET_TOKEN", t)
    //     window.open(location.origin + '/' + location.hash, '_self')
    // }
    let token = localStorage.getItem("token");
    //免受权
    var noAuthorize = false
    routes.forEach(r => {
        if (r.path == to.path && r.noAuthorize != null) {
            noAuthorize = r.noAuthorize
            return
        }
    })
    if (noAuthorize) {
        next()
    } else if (!token) {
        var path = ''
        if (to.meta.loginType == 1) {
            path = '/loginGate'
        }else if(to.meta.loginType == 3){
            path = '/loginSetSchool'
        } else {
            path = '/login'
        }
        next({path})
    } else {
        next()
    }
})


// 导航转成路由
const menuToRoute = (menu) => {
    if (!menu.component) {
        return null
    }
    let route = {
        name: menu.name,
        path: menu.path,
        meta: {
            icon: menu.icon,
            title: menu.title
        }
    }
    route.component = () => import('@/views/' + menu.component + '.vue')
    return route
}
export default router
